import "./style.css";
import xIcon from "../../images/X.svg";
import instagramIcon from "../../images/Instagram.svg";
import facebookLogo from "../../images/Facebook.svg";
import discordLogo from "../../images/Discord.svg";
import logo from "../../images/logo.svg";
import skrill from "../../images/Skrill.svg";
import visa from "../../images/Visa.svg";
import mastercard from "../../images/Mastercard.svg";
import discovery from "../../images/Discovery.svg";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer>
      <div className="container">
        <div className="row g-4">
          <div className="col-md-4 text-center text-md-start">
            <a href="/" className="footer-logo">
              <img className="footer-logo" src={logo} alt="logo1" />
            </a>
            <div className="d-md-none d-block">
              <ul className="flex-social justify-content-center">
                <li>
                  <a href="https://x.com/SpinSagaCasino" target="a_blank">
                    <img src={xIcon} alt="X" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/spinsagacasino/"
                    target="a_blank">
                    <img src={instagramIcon} alt="Instagram" />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/profile.php?id=61563257325711"
                  target="a_blank"
                  >
                    <img src={facebookLogo} alt="Facebook" />
                  </a>
                </li>
                {/* <li>
                  <a href="#">
                    <img src={discordLogo} alt="Discord" />
                  </a>
                </li> */}
              </ul>
            </div>
            <div className="flex-logo footer-logo-space d-md-flex d-none">
              <div>
                <img src={skrill} alt="Skrill" />
              </div>
              <div>
                <img src={visa} alt="Visa" />
              </div>
              <div>
                <img src={mastercard} alt="Mastercard" />
              </div>
              <div>
                <img src={discovery} alt="Discovery" />
              </div>
            </div>
          </div>
          <div className="col-md-2 col-6">
            <div className="left_sec_footer">
            <h4>SUPPORT</h4>
            <ul>
              <li>
                <a href="https://play.spinsagacasino.com/contact-us">Contact Us</a>
              </li>
              <li
              >
                <a href="https://play.spinsagacasino.com/responsible-gaming">Responsible Gaming</a>
              </li>
              <li>
                <a href="https://play.spinsagacasino.com/faq">FAQs</a>
              </li>
            </ul>
            </div>
          </div>
          <div className="col-md-4 col-6">
            <h4>PLAYING AT SPINSAGA</h4>
            <ul>
              <li>
                <a href="https://play.spinsagacasino.com/about-us">About Us</a>
              </li>
              <li>
                <a href="https://play.spinsagacasino.com/terms-conditions">Terms and Conditions</a>
              </li>
              <li>
                <a href="https://play.spinsagacasino.com/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="https://play.spinsagacasino.com/sweepstakes">Sweepstakes Rules</a>
              </li>
            </ul>
          </div>
          <div className="col-md-2">
            <div className="d-none d-md-block">
              <ul className="flex-social">
                <li>
                  <a href="https://x.com/SpinSagaCasino" target="a_blank">
                    <img src={xIcon} alt="X" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/spinsagacasino/"
                    target="a_blank"
                  >
                    <img src={instagramIcon} alt="Instagram" />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=61563257325711"
                    target="a_blank"
                  >
                    <img src={facebookLogo} alt="Facebook" />
                  </a>
                </li>
              </ul>
              {/* <ul className="flex-social">
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=61563257325711"
                    target="a_blank"
                  >
                    <img src={facebookLogo} alt="Facebook" />
                  </a>
                </li>
                <li>
                    <a href="#">
                      <img src={discordLogo} alt="Discord" />
                    </a>
                  </li>
              </ul> */}
            </div>
          </div>
        </div>

        <div className="copyright text-center">
          <div className="flex-logo footer-logo-space d-md-none">
            <a>
              <img src={skrill} alt="Skrill" />
            </a>
            <a>
              <img src={visa} alt="Visa" />
            </a>
            <a>
              <img src={mastercard} alt="Mastercard" />
            </a>
            <a>
              <img src={discovery} alt="Discovery" />
            </a>
          </div>
          <p>At SpinSaga it is ALWAYS FREE to enter or win our games. No Purchase Necessary. Void where prohibited by law. Terms apply. © SpinSaga All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
