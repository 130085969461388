import "./style.css";

import logo from "../../images/logo.svg";
import { useNavigate } from "react-router-dom";

const Header = () => {
const navigate =  useNavigate();

  return (
    <header id="header">
    <div className="container">
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid nav-flex">
          <div className="logo-sec">
            <a className="navbar-brand" href="/">
              <img src={logo} alt="logo" />
            </a>
            <div className="mobile-header_btn">
              <a href="https://play.spinsagacasino.com/login" className="red_mobile_btn">
                Login
              </a>
              <a href="https://play.spinsagacasino.com/signup" className="red_mobile_btn green_btn">
                Sign up
              </a>
            </div>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#website-nav"
            aria-controls="website-nav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="website-nav">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://play.spinsagacasino.com/about-us">
                  About
                </a>
              </li>
              <li className="nav-item">
                <a href="https://play.spinsagacasino.com/" className="nav-link">
                  Featured Games
                </a>
              </li>
              <li className="nav-item">
                <a href="https://play.spinsagacasino.com/login" className="nav-link">
                  Login
                </a>
              </li>
              <li className="nav-item">
                <a href="https://play.spinsagacasino.com/signup" className="nav-link">
                  Sign up
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </header>
  );
};
export default Header;
